import React, {useContext} from "react";
import {Button, Table} from "react-bootstrap";
import editIcon from "../styles/assets/icons/edit.svg";
import trashIcon from "../styles/assets/icons/trash.svg";
import {fetchPromises} from "../utils/fetchPromises";
import {APIClient} from "../utils/APIClient";
import {ConfirmModalContext} from "./ConfirmModalProvider.js";
import MittelPagination from "./MittelPagination";
import {withRouter} from "react-router-dom";
import {ENUM} from "../utils/enum.js";
import Image from "./Image.js";

function ArticlesTable(props) {
    const {showConfirmModal} = useContext(ConfirmModalContext);

    const onDeleteClick = async (event) => {
        const id = +event.currentTarget.dataset.id;
        showConfirmModal("Are you sure to delete article?", async () => {
            try {
                await APIClient().deleteArticle(id);
                props.refresh();
            } catch (e) {}
        });
    }

    const onPageChange = async (page) => {
        props.history.push({
            pathname: `/${ENUM.route.admin}${ENUM.route.articles}`,
            search: "?" + new URLSearchParams({
                ...props.filter,
                page
            }).toString()
        });
    }

    return (
        <>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>Image</th>
                        <th>Code</th>
                        <th>EAN Code</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Family</th>
                        <th className="text-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.articles && props.articles.articles.map((a, index) =>
                        <tr key={index}>
                            <td className="text-center"><Image image={a.image} height="30" /></td>
                            <td>{a.code}</td>
                            <td>{a.eanCodePc}</td>
                            <td>{a.name}</td>
                            <td>{a.brandName}</td>
                            <td>{a.family}</td>
                            <td className="text-end table-actions d-flex">
                                <Button
                                    className="table-actions-btn"
                                    size="md"
                                    variant="primary"
                                    onClick={props.onEditClick}
                                    data-id={a.id}
                                >
                                    <img src={editIcon} alt="edit" className="d-block" />
                                </Button>
                                <Button
                                    className="table-actions-btn"
                                    size="md"
                                    variant="danger"
                                    onClick={onDeleteClick}
                                    data-id={a.id}
                                >
                                    <img src={trashIcon} alt="delete" className="d-block" />
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
            <MittelPagination
                currentPage={props.filter.page ? +props.filter.page : 1}
                totalCount={props.articles.count}
                pageSize={props.articles.rowsPerPage}
                onPageChange={onPageChange}
            />
        </>
    );
}
export default withRouter(
    fetchPromises({
        articles: () => ({ name: 'getArticles', props: ['filter'] }),
    })(ArticlesTable)
);
