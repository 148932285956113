import {Button, Form, Modal, Table} from "react-bootstrap";
import sendIcon from "../../styles/assets/icons/send.svg";
import {fetchPromises} from "../../utils/fetchPromises";
import {withRouter} from 'react-router-dom';
import useForm from "../../hooks/useForm";
import {APIClient} from "../../utils/APIClient";
import {useContext, useRef, useState} from "react";
import AlertContext from "../../contexts/alertContext";
import {UserContext} from "../../contexts/userContext";
import BrandTableRows from "../../components/BrandTableRows.js";
import {throttle} from "lodash";

function BrandPage({articles, users, match}) {
    const {form, setForm, onInputChange} = useForm(initFormValues(+match.params.brandId, articles, users));
    const filter = useForm({});
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const alert = useContext(AlertContext);
    const user = useContext(UserContext);
    const isAdmin = user && user.admin === 'true';

    const onFilterChange = useRef(throttle(filter.onInputChange, 500, { leading: false })).current;

    const onConfirmOrder = async () => {
        const orderId = await APIClient().createOrder(form, isAdmin ? form.customerId : user.id, isAdmin ? user.id : null);
        setConfirmDisabled(true);
        if (orderId) {
            setForm(initFormValues(+match.params.brandId, articles, users));
            setShowModal(false);
            alert.success('Order successfully created!');

        } else {
            alert.error('Error while submitting order. Please contact the seller');
        }
        setConfirmDisabled(false);
    }

    const onClearFilterClick = () => {
        filter.setForm({});
    }

    const firstRow = (articles && articles[0]) || {};
    let orderHeaderColspan = 1;
    orderHeaderColspan += firstRow.allowPalletQuantity ? 1 : 0;
    orderHeaderColspan += firstRow.allowPackQuantity ? 1 : 0;

    articles && articles.forEach((a) => {
        const pallets = form.pallets[a.id].quantity;
        const packs = form.packs[a.id].quantity;
        const pcWeight = a.palletWeightGross / a.pcsPerPallet;
        const pcsQuantity = (pallets * a.pcsPerPallet) + (packs * a.pcsPerPack);

        form.pieces[a.id] = {
            ...form.pieces[a.id],
            quantity: pcsQuantity,
            weight: pcsQuantity * pcWeight,
        };
    });

    const totals = calculateTotals(form);

    return (
        <div className="w-100 d-flex flex-column overflow-hidden">
            <div className="page-header p-1 d-flex justify-content-between align-items-center">
                <Button size="sm" onClick={onClearFilterClick}>Clear Filters</Button>
                <span className="fs-5">{firstRow.brandName}</span>
                <Button size="sm" onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'Hide' : 'Show'} Details</Button>
            </div>
            <div className="page-content">
                <Table striped hover className="table small mb-0">
                    <thead>
                    <tr>
                        <th className="text-center border-right" colSpan={2}>Article</th>
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom color-orange" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        <th className="border-right no-border-bottom" />
                        {showDetails &&
                            <>
                                <th className="border-right no-border-bottom" />
                                <th className="border-right no-border-bottom" />
                                <th className="border-right no-border-bottom" />
                                <th className="text-center border-right" colSpan={2}>Volume (ml)</th>
                                <th className="text-center border-right" colSpan={2}>Weight (kg)</th>
                                <th className="text-center border-right" colSpan={2}>Weight Pack (kg)</th>
                                <th className="text-center border-right" colSpan={2}>Weight Pallet (kg)</th>
                                <th className="text-center border-right" colSpan={3}>Piece</th>
                                <th className="text-center border-right" colSpan={3}>Pack</th>
                                <th className="border-right no-border-bottom" />
                                <th className="text-center border-right" colSpan={2}>EAN Code</th>
                                <th className="border-right no-border-bottom" />
                                <th className="border-right no-border-bottom" />
                            </>
                        }
                        <th className="text-center color-blue" colSpan={orderHeaderColspan}>Order</th>
                    </tr>
                    <tr>
                        <th>
                            <p>Code</p>
                            <Form.Control size="sm" name="code" onChange={onFilterChange} />
                        </th>
                        <th className="border-right">
                            <p>Name</p>
                            <Form.Control size="sm" name="name" onChange={onFilterChange} />
                        </th>
                        <th className="border-right">
                            <p>EAN Code</p>
                            <Form.Control size="sm" name="eanCode" onChange={onFilterChange} />
                        </th>
                        <th className="border-right">
                            <p>Brand Category</p>
                            <Form.Control size="sm" name="brandCategoryName" onChange={onFilterChange} />
                        </th>
                        <th className="border-right color-orange">
                            <p>Family</p>
                            <Form.Control size="sm" name="family" onChange={onFilterChange} />
                        </th>
                        <th className="border-right">
                            <p>Sector</p>
                            <Form.Control size="sm" name="sector" onChange={onFilterChange} />
                        </th>
                        <th className="border-right">Shelf Life</th>
                        <th className="border-right">Pcs. Per Pack</th>
                        <th className="border-right">Pcs. Per Pallet</th>
                        <th className="border-right">Pallet Layers</th>
                        <th className="border-right">Packs Per Layer</th>
                        {showDetails &&
                            <>
                                <th className="border-right">Description</th>
                                <th className="border-right">TARIFF Code</th>
                                <th className="border-right">Brand Code</th>
                                <th>Gross</th>
                                <th className="border-right">Net</th>
                                <th>Gross</th>
                                <th className="border-right">Net</th>
                                <th>Gross</th>
                                <th className="border-right">Net</th>
                                <th>Gross</th>
                                <th className="border-right">Net</th>
                                <th>Length</th>
                                <th>Depth</th>
                                <th className="border-right">Height</th>
                                <th>Length</th>
                                <th>Depth</th>
                                <th className="border-right">Height</th>
                                <th className="border-right">Height Pallet</th>
                                <th>Pack</th>
                                <th className="border-right">Pallet</th>
                                <th className="border-right text-center">Spec</th>
                                <th className="border-right text-center">Image</th>
                            </>
                        }
                        {firstRow.allowPalletQuantity ? <th className="text-center color-blue">Pallets</th> : null}
                        {firstRow.allowPackQuantity ? <th className="text-center color-blue">Pack</th> : null}
                        <th className="text-center color-blue">Total Pieces</th>
                    </tr>
                    </thead>
                    <tbody>
                        <BrandTableRows form={form} rows={articles} onInputChange={onInputChange} showDetails={showDetails} filter={filter.form} />
                    </tbody>
                </Table>
            </div>
            <div className="page-bottom d-flex justify-content-between align-items-center p-2">
                <div>
                    <span className="page-bottom-total border-right pe-2 me-2"><b>Total overall</b></span>
                    {firstRow.allowPalletQuantity ? <span className="page-bottom-total border-right pe-2 me-2"><b>{totals.pallets || 0}</b> pallets</span> : null}
                    {firstRow.allowPackQuantity ? <span className="page-bottom-total border-right pe-2 me-2"><b>{totals.packs || 0}</b> packs</span> : null}
                    <span className="page-bottom-total border-right pe-2 me-2"><b>{totals.pieces || 0}</b> pcs.</span>
                    <span className="page-bottom-total me-2"><b>{totals.weight.toFixed(2) || 0}</b> kg</span>
                </div>
                <Button className="d-flex align-items-center" variant="primary" onClick={() => setShowModal(true)}>
                    <img src={sendIcon} alt="" className="me-1" />
                    <span className="d-none d-md-flex">Send Order</span>
                </Button>
            </div>
            <Modal show={showModal}>
                <Modal.Body>
                    { isAdmin ?
                        <>
                            <p>Submit order as customer</p>
                            <Form.Select name="customerId" aria-label="Select customer" onChange={onInputChange}>
                                {users.filter(u => u.admin === 'false').map(u => <option key={u.id} value={u.id}>{u.name}</option>)}
                            </Form.Select>
                        </> :
                        <>Are you sure to confirm order?</>
                    }
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button variant="outline-primary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button onClick={onConfirmOrder} disabled={confirmDisabled}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default withRouter(fetchPromises({
    articles: () => ({ name: 'getArticlesByBrandId', props: ['match.params.brandId'] }),
    users: () => ({ name: 'getUsers' })
})(BrandPage));

function calculateTotals(form) {
    const pallets = form.pallets && Object.keys(form.pallets).reduce((total, key) => total + +form.pallets[key].quantity, 0);
    const packs = form.packs && Object.keys(form.packs).reduce((total, key) => total + +form.packs[key].quantity, 0);
    const pieces = form.pieces && Object.keys(form.pieces).reduce((total, key) => total + +form.pieces[key].quantity, 0);
    const weight = (form.pieces && Object.keys(form.pieces).reduce((total, key) => total + +form.pieces[key].weight, 0)) || 0;

    return {
        pallets,
        packs,
        pieces,
        weight
    }
}

function initFormValues(brandId, articles, users) {
    const customerId = users?.filter(u => u.admin === 'false')[0]?.id;
    const form = {
        brandId,
        customerId,
        pallets: {},
        packs: {},
        pieces: {}
    }
    articles && articles.forEach((a) => {
        form.pallets[a.id] = {
            code: a.code,
            name: a.name,
            pcsPerPallet: a.pcsPerPallet,
            quantity: 0
        };
        form.packs[a.id] = {
            code: a.code,
            name: a.name,
            pcsPerPack: a.pcsPerPack,
            quantity: 0
        };
        form.pieces[a.id] = {
            code: a.code,
            eanCodePc: a.eanCodePc,
            name: a.name,
            quantity: 0
        };
    });

    return form;
}
