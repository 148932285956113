import {Button, Col, Form, Row, Table} from "react-bootstrap";
import trashIcon from "../../styles/assets/icons/trash.svg";
import editIcon from "../../styles/assets/icons/edit.svg";
import addIcon from "../../styles/assets/icons/add.svg";
import {useContext, useState} from "react";
import {MittelModal} from "../../components/Modal";
import {fetchPromises} from "../../utils/fetchPromises";
import {APIClient} from "../../utils/APIClient";
import useForm from "../../hooks/useForm";
import {ConfirmModalContext} from "../../components/ConfirmModalProvider.js";

function CountriesPage({ countries, refresh }) {
    const [show, setShow] = useState(false);
    const {form, setForm, onInputChange} = useForm({});
    const {showConfirmModal} = useContext(ConfirmModalContext);

    const onDeleteClick = async (event) => {
        const id = +event.currentTarget.dataset.id;
        showConfirmModal("Are you sure to delete country?", async () => {
            try {
                await APIClient().deleteCountry(id);
                refresh();
            } catch (e) {}
        });
    }

    const onEditClick = async (event) => {
        try {
            const data = await APIClient().getCountry(+event.currentTarget.dataset.id);
            setForm(data);
            setShow(true);
        } catch (e) {}
    }

    const onModalSaveClick = async () => {
        try {
            await APIClient().saveCountry(form);
            refresh();
        } catch (e) {}
    }

    const onModalClose = () => {
        setForm({});
        setShow(false);
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Countries</div>
                <div className="page-header-actions">
                    <Button className="d-flex align-items-center" size="sm" onClick={() => setShow(true)}>
                        <img className="me-1" src={addIcon} alt="add" />
                        <span>Add New</span>
                    </Button>
                </div>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middlen">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {countries && countries.map((c, index) =>
                            <tr key={index}>
                                <td>{c.name}</td>
                                <td className="text-end table-actions">
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="primary"
                                        onClick={onEditClick}
                                        data-id={c.id}
                                    >
                                        <img src={editIcon} alt="edit" className="d-block" />
                                    </Button>
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="danger"
                                        onClick={onDeleteClick}
                                        data-id={c.id}
                                    >
                                        <img src={trashIcon} alt="delete" className="d-block" />
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <MittelModal show={show} onClose={onModalClose} onCallback={onModalSaveClick} title="Country">
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" name="name" onChange={onInputChange} value={form.name || ''} required />
                        <Form.Control.Feedback type="invalid">
                            Field is required.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </MittelModal>
        </div>
    );
}
export default fetchPromises({
    countries: () => ({ name: 'getCountries' })
})(CountriesPage);
