import {Button, ButtonGroup, Col, DropdownButton, Form, Row, Table} from "react-bootstrap";
import exportIcon from "../../styles/assets/icons/export.svg";
import React, {useEffect, useState} from "react";
import {MittelModal} from "../../components/Modal";
import {fetchPromises} from "../../utils/fetchPromises";
import moment from "moment";
import CsvDownloader from 'react-csv-downloader';
import {APIClient} from "../../utils/APIClient.js";
import useForm from "../../hooks/useForm.js";
import {countFilters} from "../../utils/helper.js";

function OrdersPage(props) {
    const [show, setShow] = useState(false);
    const [orders, setOrders] = useState(props.orders);
    const [filters, setFilters] = useState(0);

    useEffect(() => {
        setOrders(props.orders);
    }, [props.orders]);

    const onExportClick = async (orderId) => {
        const order = await APIClient().getOrderExport(orderId);
        const exportOrder = [
            {
                1: 'TES',
                2: moment(order[0].created).format("DD/MM/YYYY"),
                3: 1,
                4: moment(order[0].created).format("DD/MM/YYYY"),
                5: order[0].id,
                6: order[0].userId,
                7: 0,
                8: order[0].paymentConditionCode,
                9: null,
                10: null,
                11: null,
                12: null
            }
        ];

        order.forEach((o) => {
            exportOrder.push({
                1: 'RIG',
                2: moment(o.created).format("DD/MM/YYYY"),
                3: 1,
                4: moment(o.created).format("DD/MM/YYYY"),
                5: o.id,
                6: o.userId,
                7: 0,
                8: o.paymentConditionCode,
                9: 1,
                10: o.code,
                11: o.quantityPieces,
                12: moment(o.created).add(2, 'week').format("DD/MM/YYYY")
            });
        });

        return exportOrder;
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Orders</div>
                <div className="page-header-actions">
                    <DropdownButton
                        as={ButtonGroup}
                        variant="primary"
                        title={`Filters (${filters})`}
                    >
                        <Filters setOrders={setOrders} setFilters={setFilters} />
                    </DropdownButton>
                </div>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Submitted</th>
                        <th>Brand</th>
                        <th>Created</th>
                        <th className="text-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {orders && orders.map((o, index) =>
                            <tr key={index}>
                                <td>{o.id}</td>
                                <td>{o.user}</td>
                                <td>{o.submitted}</td>
                                <td>{o.brand}</td>
                                <td>{moment(o.created).format("DD.MM.YYYY HH:mm:ss")}</td>
                                <td className="text-end table-actions">
                                    <CsvDownloader
                                        filename={`export_${o.id}`}
                                        extension=".csv"
                                        separator=";"
                                        columns={columns}
                                        datas={() => onExportClick(o.id)}
                                        data-id={o.id}
                                    >
                                        <Button className="table-actions-btn" size="sm" variant="info">
                                            <img src={exportIcon} alt="export" /> Export
                                        </Button>
                                    </CsvDownloader>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <MittelModal show={show} setShow={setShow} title="Country">
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Country Name
                    </Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" name="country" />
                    </Col>
                </Form.Group>
            </MittelModal>
        </div>
    );
}
export default fetchPromises({
    orders: () => ({ name: 'getOrders' })
})(OrdersPage);

const columns = [
    {
        id: '1',
        displayName: 'TipoRecord'
    },
    {
        id: '2',
        displayName: 'DataRegistrazione'
    },
    {
        id: '3',
        displayName: 'NumeroRegistrazione'
    },
    {
        id: '4',
        displayName: 'DataOrdineCliente'
    },
    {
        id: '5',
        displayName: 'NumeroOrdineCliente'
    },
    {
        id: '6',
        displayName: 'CodiceCliente'
    },
    {
        id: '7',
        displayName: 'IndSped'
    },
    {
        id: '8',
        displayName: 'CondizionePagamento'
    },
    {
        id: '9',
        displayName: 'TipoRiga'
    },
    {
        id: '10',
        displayName: 'CodiceBarre'
    },
    {
        id: '11',
        displayName: 'Quantita'
    },
    {
        id: '12',
        displayName: 'DataConsegna'
    }
];

function Filter({users, setOrders, setFilters}) {
    const {form, setForm, onInputChange} = useForm({});

    const onFilterClick = async () => {
        const orders = await APIClient().getOrders(form.userId);
        setFilters(countFilters(form));
        setOrders(orders);
    };

    const onClearClick = async () => {
        setForm({});
        setFilters(0);
        const orders = await APIClient().getOrders();
        setOrders(orders);
    };

    return (
        <div className="page-header-filter">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    User
                </Form.Label>
                <Col sm="9">
                    <Form.Select name="userId" onChange={onInputChange} value={form.userId}>
                        <option key={0} value={null} />
                        {users && users.map((u, index) =>
                            <option key={index} value={u.id}>{u.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Form.Group>
            <div className="d-flex justify-content-between">
                <Button onClick={onClearClick}>Clear</Button>
                <Button onClick={onFilterClick}>Filter</Button>
            </div>
        </div>
    );
}
const Filters = fetchPromises({
    users: () => ({ name: 'getUsers' })
})(Filter);
